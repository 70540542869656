/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */

import ProductCard from 'Component/ProductCard';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { PRODUCTS_PRELOAD_COUNT } from 'Util/Product';
import { AfterPriority } from 'Util/Request/LowPriorityRender';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import {
    ProductListPageComponent as SourceProductListPageComponent
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import './ProductListPage.override.style';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

/**
 * ProductList Page
 * @class ProductListPage
 /* @namespace Component/ProductListPage/Component */
 export class ProductListPageComponent extends SourceProductListPageComponent {

    renderSliderPlaceholders() {
        const {
            numberOfPlaceholders = DEFAULT_PLACEHOLDER_COUNT,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => {
                if (i < PRODUCTS_PRELOAD_COUNT) {
                    return (
                        <SwiperSlide>
                            <ProductCard
                                key={ i }
                                product={ {} }
                                layout={ layout }
                                onLoad={ setLoadedFlag }
                            />
                        </SwiperSlide>
                        
                    );
                }

                return (
                    // @ts-ignore
                    <SwiperSlide>
                        <AfterPriority fallback={ <div style={ { minHeight: 200 } } /> }>
                            <ProductCard
                            key={ i }
                            product={ {} }
                            layout={ layout }
                            onLoad={ setLoadedFlag }
                            />
                        </AfterPriority>
                    </SwiperSlide>
                    
                );
            },
        );
    }

    renderSliderPlaceholderItems() {
        return (
            <>
                <div
                  block="ProductListPage"
                  elem="Offset"
                  ref={ this.getPlaceholderRef() }
                />
                { this.renderSliderPlaceholders() }
            </>
        );
    }

    renderSliderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        return items.map((product, i) => {
            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <SwiperSlide>
                        <ProductCard
                            product={ product }
                            // eslint-disable-next-line react/no-array-index-key
                            key={ i }
                            selectedFilters={ selectedFilters }
                            layout={ layout }
                            { ...this.containerProps() }
                            onLoad={ setLoadedFlag }
                        />
                    </SwiperSlide>
                    
                );
            }

            return (
                // @ts-ignore
                <SwiperSlide>
                    <AfterPriority fallback={ <div style={ { minHeight: 200 } } /> }>
                        <ProductCard
                            product={ product }
                            // eslint-disable-next-line react/no-array-index-key
                            key={ i }
                            selectedFilters={ selectedFilters }
                            layout={ layout }
                            { ...this.containerProps() }
                            onLoad={ setLoadedFlag }
                        />
                    </AfterPriority>
                </SwiperSlide>
                
            );
        });
    }

    renderSliderItems() {
        const { items, isLoading, title } = this.props;
        
        if (isLoading) {
            return this.renderSliderPlaceholderItems();
        }
        
        if(!items.length){
            return null
        }

        const nextSliderClass = title ? 'products-next-'+ title.replace(/\s+/g, '') : '';
        const prevSliderClass = title ? 'products-prev-'+ title.replace(/\s+/g, '') : '';

        return (
            <div block="ProductListPage" elem="SliderWrapper">
                <Swiper
                    spaceBetween={15}
                    slidesPerView={2}
                    navigation={{
                        nextEl: '.'+nextSliderClass,
                        prevEl: '.'+prevSliderClass,
                    }} 
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                        },
                        768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        },
                        1024: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                        },
                    }}
                >
                    {this.renderSliderPageItems()}
                </Swiper>
                <div className={"swiper-button-prev " + prevSliderClass} />
                <div className={"swiper-button-next " + nextSliderClass} />
            </div>
        );
    }

    render() {
        const {
            pageNumber,
            wrapperRef,
            mix,
            isPlp
        } = this.props;

        if(!isPlp){
            return this.renderSliderItems();
        }

        return (
            <div
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              key={ pageNumber }
              ref={ wrapperRef }
            >
                { this.renderItems() }
            </div>
        );
    }
 }

 export default ProductListPageComponent;
